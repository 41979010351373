
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  LOCAL_STORAGE,
  PAGE_TITLE_PATH,
  ROUTE_PAGES,
  SWAL_MESSAGES,
  VALUE,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import Vaccinate from "@/presentation/components/Vaccinate.vue";
import AddWeight from "@/presentation/components/AddWeight.vue";
import CreateVaccine from "@/presentation/components/CreateVaccine.vue";
import { useRoute, useRouter } from "vue-router";
import { BarnyardDetailModel } from "@/domain/barnyard/barnyardDetail/model/BarnyardDetailModel";

export default defineComponent({
  name: COMPONENT_NAME.BARNYARD_ANIMALS,
  components: {
    Vaccinate,
    CreateVaccine,
    AddWeight,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const swalNotification = new SwalNotification();
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const barnyardDetail = ref<BarnyardDetailModel>({});
    const barnyardId = route.params["id"];

    const tenantName = ref({
      activeTenant: localStorage.getItem(LOCAL_STORAGE.ACTIVE_TENANT),
    });

    const barnyardController = store.state.ControllersModule.barnyardController;

    if (!barnyardController) {
      throw new Error("BarnyardController is not available in Vuex store.");
    }

    const getBarnyardDetail = (barnyardId: number) => {
      const barnyardDetailModel: BarnyardDetailModel = {
        id: barnyardId,
      };
      barnyardController
        .getBarnyardDetail(barnyardDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(barnyardDetail.value, response.getValue());
          } else {
            swalNotification.error(
            response.error.cause.cause + t(SWAL_MESSAGES.ERROR_CODE) + response.error.code + response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
          );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const routeToCreateAnimal = () => {
      store.dispatch("selectedBarnyardId", barnyardId);
      router.push({
        name: ROUTE_PAGES.CREATE_ANIMAL,
      });
    };

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.BARNYARD));
      getBarnyardDetail(Number(barnyardId));
    });

    return {
      formRef,
      loading,
      PAGE_TITLE_PATH,
      barnyardId,
      barnyardDetail,
      tenantName,
      routeToCreateAnimal,
    };
  },
});
