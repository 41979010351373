<template>
  <div class="card">
    <div class="card-body pt-0 pb-0 bg-primary">
      <div class="card-header border-0" style="padding: 0">
        <div class="card-title fw-bolder fs-2 mb-2 mt-5 text-inverse-info">
          {{ $t("menuPages.barnyardPanel.tabMenu.padokInfo") }}
        </div>
        <!--begin::Actions-->
        <div class="d-flex align-items-center py-1">
          <!--begin::Button-->
          <a
          @click="routeToCreateAnimal()"
            class="btn btn-sm btn-secondary"
            style="background-color: white"
            id="kt_toolbar_primary_button"
          >
            {{ $t(PAGE_TITLE_PATH.ANIMAL_CREATE) }}
          </a>
          <!--end::Button-->
        </div>
        <!--end::Actions-->
      </div>

      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="card-body border-top">
          <div class="row mb-2">
            <div class="col-md-4">
              <div class="min-w-125px py-3 px-4 me-6 mb-3">
                <!--begin::Label-->
                <div class="fw-bold fs-6 text-inverse-info">
                  {{ $t("menuPages.barnyardPanel.companyName") }}
                </div>
                <!--end::Label-->
                <!--begin::Number-->
                <div class="d-flex align-items-center">
                  <div
                    class="fw-bolder text-inverse-info"
                    data-kt-countup="true"
                    data-kt-countup-value="75"
                    style="font-size: 25px"
                  >
                    {{ tenantName.activeTenant }}
                  </div>
                </div>
                <!--end::Number-->
              </div>
            </div>
            <div class="col-md-4">
              <div class="min-w-125px py-3 px-4 me-6 mb-3">
                <!--begin::Label-->
                <div class="fw-bold fs-6 text-inverse-info">
                  {{ $t("menuPages.barnyardPanel.barnyardName") }}
                </div>
                <!--end::Label-->
                <!--begin::Number-->
                <div class="d-flex align-items-center">
                  <div
                    class="fw-bolder text-inverse-info"
                    data-kt-countup="true"
                    data-kt-countup-value="75"
                    style="font-size: 25px"
                  >
                    {{ barnyardDetail.name }}
                  </div>
                </div>
                <!--end::Number-->
              </div>
            </div>
            <div class="col-md-2">
              <div class="min-w-125px py-3 px-4 me-6 mb-3">
                <!--begin::Label-->
                <div class="fw-bold fs-6 text-inverse-info">
                  {{ $t("menuPages.barnyardPanel.capacity") }}
                </div>
                <!--end::Label-->
                <!--begin::Number-->
                <div class="d-flex align-items-center">
                  <div
                    class="fw-bolder text-inverse-info"
                    data-kt-countup="true"
                    data-kt-countup-value="75"
                    style="font-size: 25px"
                  >
                    {{ barnyardDetail.animalCount }} /
                    {{ barnyardDetail.capacity }}
                  </div>
                </div>
                <!--end::Number-->
              </div>
            </div>
            <div class="col-md-2">
              <div class="min-w-125px py-3 px-4 me-6 mb-3">
                <!--begin::Label-->
                <div class="fw-bold fs-6 text-inverse-info">Doluluk</div>
                <!--end::Label-->
                <!--begin::Number-->
                <div class="d-flex align-items-center">
                  <div
                    class="fw-bolder text-inverse-info"
                    data-kt-countup="true"
                    data-kt-countup-value="75"
                    style="font-size: 25px"
                  >
                    %
                    {{
                      (
                        (Number(barnyardDetail.animalCount) /
                          Number(barnyardDetail.capacity)) *
                        100
                      ).toFixed(2)
                    }}
                  </div>
                </div>
                <!--end::Number-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  LOCAL_STORAGE,
  PAGE_TITLE_PATH,
  ROUTE_PAGES,
  SWAL_MESSAGES,
  VALUE,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import Vaccinate from "@/presentation/components/Vaccinate.vue";
import AddWeight from "@/presentation/components/AddWeight.vue";
import CreateVaccine from "@/presentation/components/CreateVaccine.vue";
import { useRoute, useRouter } from "vue-router";
import { BarnyardDetailModel } from "@/domain/barnyard/barnyardDetail/model/BarnyardDetailModel";

export default defineComponent({
  name: COMPONENT_NAME.BARNYARD_ANIMALS,
  components: {
    Vaccinate,
    CreateVaccine,
    AddWeight,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const swalNotification = new SwalNotification();
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const barnyardDetail = ref<BarnyardDetailModel>({});
    const barnyardId = route.params["id"];

    const tenantName = ref({
      activeTenant: localStorage.getItem(LOCAL_STORAGE.ACTIVE_TENANT),
    });

    const barnyardController = store.state.ControllersModule.barnyardController;

    if (!barnyardController) {
      throw new Error("BarnyardController is not available in Vuex store.");
    }

    const getBarnyardDetail = (barnyardId: number) => {
      const barnyardDetailModel: BarnyardDetailModel = {
        id: barnyardId,
      };
      barnyardController
        .getBarnyardDetail(barnyardDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(barnyardDetail.value, response.getValue());
          } else {
            swalNotification.error(
            response.error.cause.cause + t(SWAL_MESSAGES.ERROR_CODE) + response.error.code + response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
          );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const routeToCreateAnimal = () => {
      store.dispatch("selectedBarnyardId", barnyardId);
      router.push({
        name: ROUTE_PAGES.CREATE_ANIMAL,
      });
    };

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.BARNYARD));
      getBarnyardDetail(Number(barnyardId));
    });

    return {
      formRef,
      loading,
      PAGE_TITLE_PATH,
      barnyardId,
      barnyardDetail,
      tenantName,
      routeToCreateAnimal,
    };
  },
});
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>
